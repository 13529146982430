var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "social"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('Icon', {
    attrs: {
      "type": "logo-github",
      "size": "42",
      "color": "#181617"
    }
  })], 1), _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v("Github")]), _c('div', {
    staticClass: "desc"
  }, [_vm.relate.github ? _c('span', [_vm._v("已绑定Github账号：" + _vm._s(_vm.relate.githubUsername))]) : _c('span', [_vm._v("当前未绑定Github账号")])])])]), _c('div', [!_vm.relate.github ? _c('a', {
    on: {
      "click": function ($event) {
        return _vm.toRelateGithub();
      }
    }
  }, [_vm._v("立即绑定")]) : _c('a', {
    on: {
      "click": function ($event) {
        return _vm.unRelateGithub();
      }
    }
  }, [_vm._v("解除绑定")])])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "social"
  }, [_vm._m(0), _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v("QQ")]), _c('div', {
    staticClass: "desc"
  }, [_vm.relate.qq ? _c('span', [_vm._v("已绑定QQ账号：" + _vm._s(_vm.relate.qqUsername))]) : _c('span', [_vm._v("当前未绑定QQ账号")])])])]), _c('div', [!_vm.relate.qq ? _c('a', {
    on: {
      "click": function ($event) {
        return _vm.toRelateQQ();
      }
    }
  }, [_vm._v("立即绑定")]) : _c('a', {
    on: {
      "click": function ($event) {
        return _vm.unRelateQQ();
      }
    }
  }, [_vm._v("解除绑定")])])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "social"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('Icon', {
    attrs: {
      "custom": "iconfont icon-weixin",
      "size": "40",
      "color": "#60c126"
    }
  })], 1), _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v("微信")]), _c('div', {
    staticClass: "desc"
  }, [_vm.relate.wechat ? _c('span', [_vm._v("已绑定微信账号：" + _vm._s(_vm.relate.wechatUsername))]) : _c('span', [_vm._v("当前未绑定微信账号")])])])]), _c('div', [!_vm.relate.wechat ? _c('a', {
    on: {
      "click": function ($event) {
        return _vm.toRelateWechat();
      }
    }
  }, [_vm._v("立即绑定")]) : _c('a', {
    on: {
      "click": function ($event) {
        return _vm.unRelateWechat();
      }
    }
  }, [_vm._v("解除绑定")])])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "social"
  }, [_vm._m(1), _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v("微博")]), _c('div', {
    staticClass: "desc"
  }, [_vm.relate.weibo ? _c('span', [_vm._v("已绑定微博账号：" + _vm._s(_vm.relate.weiboUsername))]) : _c('span', [_vm._v("当前未绑定微博账号")])])])]), _c('div', [!_vm.relate.weibo ? _c('a', {
    on: {
      "click": function ($event) {
        return _vm.toRelateWeibo();
      }
    }
  }, [_vm._v("立即绑定")]) : _c('a', {
    on: {
      "click": function ($event) {
        return _vm.unRelateWeibo();
      }
    }
  }, [_vm._v("解除绑定")])])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "social"
  }, [_vm._m(2), _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v("钉钉")]), _c('div', {
    staticClass: "desc"
  }, [_vm.relate.dingding ? _c('span', [_vm._v("已绑定钉钉账号：" + _vm._s(_vm.relate.dingdingUsername))]) : _c('span', [_vm._v("当前未绑定钉钉账号")])])])]), _c('div', [!_vm.relate.dingding ? _c('a', {
    on: {
      "click": function ($event) {
        return _vm.toRelateDingding();
      }
    }
  }, [_vm._v("立即绑定")]) : _c('a', {
    on: {
      "click": function ($event) {
        return _vm.unRelateDingding();
      }
    }
  }, [_vm._v("解除绑定")])])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "social"
  }, [_vm._m(3), _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v("企业微信")]), _c('div', {
    staticClass: "desc"
  }, [_vm.relate.workwechat ? _c('span', [_vm._v("已绑定企业微信账号：" + _vm._s(_vm.relate.workwechatUsername))]) : _c('span', [_vm._v("当前未绑定企业微信")])])])]), _c('div', [!_vm.relate.workwechat ? _c('a', {
    on: {
      "click": function ($event) {
        return _vm.toRelateWorkwechat();
      }
    }
  }, [_vm._v("立即绑定")]) : _c('a', {
    on: {
      "click": function ($event) {
        return _vm.unRelateWorkwechat();
      }
    }
  }, [_vm._v("解除绑定")])])]), _vm.jumping ? _c('Spin', {
    attrs: {
      "fix": ""
    }
  }, [_vm._v("跳转中...")]) : _vm._e()], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon/qq.png"),
      "width": "42px"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon/weibo.png"),
      "width": "41px"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon/dingding.png"),
      "width": "41px"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon/qiyeweixin.png"),
      "width": "41px"
    }
  })]);
}];
export { render, staticRenderFns };