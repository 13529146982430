import { relatedInfo, unRelate, githubLogin, qqLogin, weiboLogin, wechatLogin, dingdingLogin, workwechatLogin } from "@/api/index";
export default {
  components: {},
  name: "social",
  data() {
    return {
      relate: {},
      jumping: false
    };
  },
  methods: {
    init() {
      let userInfo = this.getUserInfo();
      relatedInfo(userInfo.username).then(res => {
        if (res.success) {
          this.relate = res.result;
        } else {
          this.$Message.error("加载绑定第三方账号信息失败");
        }
      });
    },
    toRelateGithub() {
      this.jumping = true;
      githubLogin().then(res => {
        if (res.success) {
          window.location.href = res.result;
        } else {
          this.jumping = false;
        }
      });
    },
    toRelateQQ() {
      this.jumping = true;
      qqLogin().then(res => {
        if (res.success) {
          window.location.href = res.result;
        } else {
          this.jumping = false;
        }
      });
    },
    toRelateWeibo() {
      this.jumping = true;
      weiboLogin().then(res => {
        if (res.success) {
          window.location.href = res.result;
        } else {
          this.jumping = false;
        }
      });
    },
    toRelateWechat() {
      this.jumping = true;
      wechatLogin().then(res => {
        if (res.success) {
          window.location.href = res.result;
        } else {
          this.jumping = false;
        }
      });
    },
    toRelateDingding() {
      this.jumping = true;
      dingdingLogin().then(res => {
        if (res.success) {
          window.location.href = res.result;
        } else {
          this.jumping = false;
        }
      });
    },
    toRelateWorkwechat() {
      this.jumping = true;
      workwechatLogin().then(res => {
        if (res.success) {
          window.location.href = res.result;
        } else {
          this.jumping = false;
        }
      });
    },
    unRelateGithub() {
      this.$Modal.confirm({
        title: "确认解绑Github账号",
        content: "您确认要解除绑定 " + this.relate.githubUsername + " ?",
        loading: true,
        onOk: () => {
          let params = {
            ids: [this.relate.githubId]
          };
          unRelate(params).then(res => {
            this.$Modal.remove();
            if (res.success) {
              this.$Message.success("操作成功");
              this.relate.github = false;
            }
          });
        }
      });
    },
    unRelateQQ() {
      this.$Modal.confirm({
        title: "确认解绑QQ账号",
        content: "您确认要解除绑定 " + this.relate.qqUsername + " ?",
        loading: true,
        onOk: () => {
          let params = {
            ids: [this.relate.qqId]
          };
          unRelate(params).then(res => {
            this.$Modal.remove();
            if (res.success) {
              this.$Message.success("操作成功");
              this.relate.qq = false;
            }
          });
        }
      });
    },
    unRelateWeibo() {
      this.$Modal.confirm({
        title: "确认解绑微博账号",
        content: "您确认要解除绑定 " + this.relate.weiboUsername + " ?",
        loading: true,
        onOk: () => {
          let params = {
            ids: [this.relate.weiboId]
          };
          unRelate(params).then(res => {
            this.$Modal.remove();
            if (res.success) {
              this.$Message.success("操作成功");
              this.relate.weibo = false;
            }
          });
        }
      });
    },
    unRelateWechat() {
      this.$Modal.confirm({
        title: "确认解绑微信账号",
        content: "您确认要解除绑定 " + this.relate.wechatUsername + " ?",
        loading: true,
        onOk: () => {
          let params = {
            ids: [this.relate.wechatId]
          };
          unRelate(params).then(res => {
            this.$Modal.remove();
            if (res.success) {
              this.$Message.success("操作成功");
              this.relate.wechat = false;
            }
          });
        }
      });
    },
    unRelateDingding() {
      this.$Modal.confirm({
        title: "确认解绑钉钉账号",
        content: "您确认要解除绑定 " + this.relate.dingdingUsername + " ?",
        loading: true,
        onOk: () => {
          let params = {
            ids: [this.relate.dingdingId]
          };
          unRelate(params).then(res => {
            this.$Modal.remove();
            if (res.success) {
              this.$Message.success("操作成功");
              this.relate.dingding = false;
            }
          });
        }
      });
    },
    unRelateWorkwechat() {
      this.$Modal.confirm({
        title: "确认解绑企业微信账号",
        content: "您确认要解除绑定 " + this.relate.workwechatUsername + " ?",
        loading: true,
        onOk: () => {
          let params = {
            ids: [this.relate.workwechatId]
          };
          unRelate(params).then(res => {
            this.$Modal.remove();
            if (res.success) {
              this.$Message.success("操作成功");
              this.relate.workwechat = false;
            }
          });
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};